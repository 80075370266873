import React, { useState, useEffect } from "react"
import * as Sentry from "@sentry/gatsby"

import { useApp } from "../../hooks/useApp"
import { loadCheckout, createBraintreeClient, createPaypalCheckoutInstance, handleCharge, usePaymentData } from "../../hooks/usePayments"
import { useLanguage } from "../../hooks/useLanguage"
import { useRoutes } from "../../hooks/useRoutes"
import { useLocation } from "../../hooks/useLocation"
import { useSentry } from "../../hooks/useSentry"

export const withCharge = Component => ({ name = `Charge`, orderId }) => {
  const {
    config: {
      settings: { params },
    },
  } = useApp()
  const { customPaymentPolicy } = usePaymentData()
  const { getUrlParameter } = useRoutes()
  const { shopifyStore } = useLocation()
  const { sendSentryEvent } = useSentry()

  const [checkout, setCheckout] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [braintreeId, setBraintreeId] = useState(null)
  const [amount, setAmount] = useState("")
  const [paymentType, setPaymentType] = useState("additional")
  const [braintreeClient, setBraintreeClient] = useState(null)
  const [paypalCheckout, setPaypalCheckout] = useState(null)

  const [customerId, setCustomerId] = useState(null)
  const [redirectUrl, setRedirectUrl] = useState(null)

  const [checkoutError, setCheckoutError] = useState(null)
  const [serverError, setServerError] = useState(null)

  const locales = useLanguage(`payment`)

  useEffect(() => {
    const resolveParams = () => {
      const encodedParams = getUrlParameter(params.checkoutID)
      const decodedParamString = atob(encodedParams)
      const decodedParams = decodedParamString.split("&")

      for (const param of decodedParams) {
        if (param.includes("customer_id=")) {
          setCustomerId(param.substr("customer_id=".length))
        }
        if (param.includes("redirect_url=")) {
          setRedirectUrl(param.substr("redirect_url=".length))
        }
        if (param.includes("payment_type=")) {
          setPaymentType(param.substr("payment_type=".length))
        }
      }
    }
    resolveParams()
  }, [])

  useEffect(() => {
    if (customerId && shopifyStore) {
      const fetchCheckout = async () => {
        const result = await loadCheckout(customerId, orderId, shopifyStore)
        if (result) {
          setCheckout(result)
          setBraintreeId(result.braintreeCustomerId)
        }
        setIsLoading(false)
      }
      fetchCheckout()
    }
  }, [customerId, shopifyStore])

  useEffect(() => {
    if (!braintreeClient && checkout && shopifyStore) {
      const createBTClient = async () => {
        const client = await createBraintreeClient(braintreeId, shopifyStore)
        setBraintreeClient(client)
      }
      createBTClient()
    }
  }, [braintreeId, shopifyStore])

  useEffect(() => {
    if (!paypalCheckout && braintreeClient) {
      const createPPClient = async () => {
        const client = await createPaypalCheckoutInstance(braintreeClient)
        setPaypalCheckout(client)
      }
      createPPClient()
    }
  }, [braintreeClient])

  useEffect(() => {
    if (paypalCheckout && shopifyStore) {
      const createPaypalButton = async () => {
        await paypalCheckout.loadPayPalSDK({
          currency: "AUD",
          intent: "capture",
        })
        window.paypal
          .Buttons({
            fundingSource: window.paypal.FUNDING.PAYPAL,

            createOrder: function () {
              return paypalCheckout.createPayment({
                flow: "checkout", // Required
                amount: parseFloat(amount), // Required
                currency: "AUD", // Required, must match the currency passed in with loadPayPalSDK
                intent: "capture", // Must match the intent passed in with loadPayPalSDK
              })
            },

            onApprove: function (data) {
              setIsSubmitting(true)
              return paypalCheckout.tokenizePayment(data).then(function (payload) {
                // Submit `payload.nonce` to your server

                const nonce = payload.nonce
                if (!amount) {
                  console.error(`[ERROR] Invalid amount`)
                  sendSentryEvent({
                    message: `[ERROR][${orderId}] Invalid amount`,
                    tag: "paypal_charge",
                    level: Sentry.Severity.Error,
                  })
                  setIsSubmitting(false)
                  return false
                }

                if (!customerId) {
                  console.error(`[ERROR] Invalid customer id`)
                  sendSentryEvent({
                    message: `[ERROR][${orderId}] customer id`,
                    tag: "paypal_charge",
                    level: Sentry.Severity.Error,
                  })
                  setIsSubmitting(false)
                  return false
                }

                handleCharge(customerId, orderId, amount, nonce, paymentType, shopifyStore)
                  .then(() => {
                    window.location.href = redirectUrl
                    setIsSubmitting(false)
                  })
                  .catch(err => {
                    console.error(`[ERROR] ${err.message}`)
                    sendSentryEvent({
                      message: `[handle paypal Charge][${orderId}][ERROR] ${err.message}`,
                      tag: "paypal_charge",
                      level: Sentry.Severity.Error,
                    })
                    setIsSubmitting(false)
                  })
              })
            },

            onCancel: function (data) {
              sendSentryEvent({
                message: `[handle paypal Charge][${orderId}] ${JSON.stringify(data)}`,
                tag: "paypal_charge",
                level: Sentry.Severity.Warning,
                responsePayload: `${JSON.stringify(data)}`,
              })
              console.warn("PayPal payment cancelled", JSON.stringify(data, 0, 2))
            },

            onError: function (err) {
              sendSentryEvent({
                message: `[handle paypal Charge][${orderId}][ERROR] ${err}`,
                tag: "paypal_charge",
                level: Sentry.Severity.Error,
                error: err,
              })
              console.error("PayPal error", err)
            },
          })
          .render("#paypal-button-charge")
      }
      createPaypalButton()
    }
  }, [paypalCheckout, amount, shopifyStore])

  const handleSubmit = async customerData => {
    setCheckoutError(null)
    setServerError(null)

    if (!shopifyStore) {
      setCheckoutError(`Internal error. Looks like something went wrong on our end. Please try again later or contact our support team.`)
      sendSentryEvent({
        message: `[handle Normal Charge]${orderId}[ERROR] Missing shop name`,
        tag: "charge",
        level: Sentry.Severity.Error,
      })
      return
    }

    setIsSubmitting(true)
    const data = {
      creditCard: {
        number: customerData.cardNumber,
        cvv: customerData.securityCode,
        expirationDate: customerData.expirationDate,
        billingAddress: {
          postalCode: checkout.order.billingAddress.zip,
        },
      },
    }

    braintreeClient.request(
      {
        endpoint: "payment_methods/credit_cards",
        method: "post",
        data: data,
      },
      (err, response) => {
        if (!err) {
          const nonce = response.creditCards[0].nonce
          if (!amount) {
            console.error(`[ERROR] Invalid amount`)
            setServerError(`[ERROR] Invalid amount`)
            sendSentryEvent({
              message: `[handle Normal Charge][${orderId}][ERROR] Invalid plan amount`,
              tag: "charge",
              level: Sentry.Severity.Error,
            })
            return false
          }

          if (!customerId) {
            console.error(`[ERROR] Invalid customer id`)
            setServerError(`[ERROR] Invalid customer id`)
            sendSentryEvent({
              message: `[handle Normal Charge][${orderId}][ERROR] Invalid customer id`,
              tag: "charge",
              level: Sentry.Severity.Error,
            })
            return false
          }

          handleCharge(customerId, orderId, amount, nonce, paymentType, shopifyStore)
            .then(() => {
              window.location.href = redirectUrl
              setIsSubmitting(false)
            })
            .catch(err => {
              console.error(`[ERROR] ${err.message}`)
              setIsSubmitting(false)
              setServerError(`[ERROR] ${err.message}`)
              return false
            })
        } else {
          console.error(`[ERROR] ${err}`)
          setIsSubmitting(false)
          setCheckoutError(`Your payment details couldn’t be verified. Check your card details and try again.`)
          sendSentryEvent({
            message: `[handle Normal Charge][${orderId}][ERROR] payment details couldn’t be verified - ${JSON.stringify(data)}`,
            tag: "charge",
            level: Sentry.Severity.Error,
            error: err,
            responsePayload: `${JSON.stringify(data)}`,
          })
          return false
        }
      }
    )
  }

  const handleSetAmount = value => {
    setAmount(value)
  }

  Component.displayName = name
  return (
    <Component
      amount={amount}
      checkout={checkout}
      checkoutError={checkoutError}
      customPaymentPolicy={customPaymentPolicy}
      handleSetAmount={handleSetAmount}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      locales={locales}
      setAmount={setAmount}
      serverError={serverError}
    />
  )
}

import React from "react"

import { withCharge } from "./withCharge"
import { CheckoutSection } from "./Section/CheckoutSection"

interface Props {
  amount: any
  checkout: any
  checkoutError: any
  customPaymentPolicy: any
  handleSubmit: Function
  handleSetAmount: Function
  isLoading: any
  isSubmitting: any
  locales: any
  serverError: any
}

export const Charge = withCharge(
  ({ amount, checkout, checkoutError, customPaymentPolicy, handleSubmit, handleSetAmount, isLoading, isSubmitting, locales, serverError }: Props) => (
    <CheckoutSection
      amount={amount}
      checkout={checkout}
      checkoutError={checkoutError}
      contribution
      customPaymentPolicy={customPaymentPolicy}
      isLoading={isLoading}
      locales={locales}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      serverError={serverError}
      setAmount={handleSetAmount}
    />
  )
)
